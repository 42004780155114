import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import styles from './Support.module.css';
import Member from '../Member/Member';
import Phone, { PHONE_TYPE } from '../Member/Phone/Phone';
import imagePath from '../../../utils/imagePath';
import Modal from '../../../Shared/Modal/Modal';

const Support = ({ members }) => {
    const [entregasIsOpen, setEntregasIsOpen] = useState(false);
    const [cuentasIsOpen, setCuentasIsOpen] = useState(false);

    const membersNode = useMemo(() => {
        return members.map(member => {
            return <Member member={member} key={member.name} />;
        });
    }, [members]);

    const onEntregasClose = useCallback(() => {
        setEntregasIsOpen(false);
    }, []);
    const openEntregas = useCallback(() => {
        setEntregasIsOpen(true);
    }, []);

    const onCuentasClose = useCallback(() => {
        setCuentasIsOpen(false);
    }, []);
    const openCuentas = useCallback(() => {
        setCuentasIsOpen(true);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.left}>{membersNode}</div>
            <div className={styles.right}>
                <div className={styles.admin}>
                    <h3 className={styles.title}>ADMINISTRACIÓN</h3>
                    <Phone type={PHONE_TYPE.TELEPHONE} number="(+54) 11 4117-1900" />
                    <span className={styles.email}>
                        <img alt="" src={imagePath('/img/icons/email.svg')} />
                        MAIL: <a href="mailto:administracion@sectorinformatico.com.ar">administracion@sectorinformatico.com.ar</a>
                    </span>

                    <span className={styles.rrss}>
                        <FontAwesomeIcon icon={faFacebook} className={styles.icon} />
                        <a href="https://www.facebook.com/Sector-Informatico-137846712923983/" target="_blank" rel="noreferrer noopener">
                            /sectorinformaticosa
                        </a>
                    </span>
                    <span className={styles.rrss}>
                        <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
                        <a href="https://www.instagram.com/sectorinformaticosa/" target="_blank" rel="noreferrer noopener">
                            @sectorinformaticosa
                        </a>
                    </span>
                    <span className={styles.rrss}>
                        <FontAwesomeIcon icon={faTwitter} className={styles.icon} />
                        <a href="https://twitter.com/sectorinfsa" target="_blank" rel="noreferrer noopener">
                            @sectorinfsa
                        </a>
                    </span>
                    <span className={styles.rrss}>
                        <FontAwesomeIcon icon={faLinkedinIn} className={styles.icon} />
                        <a href="https://www.linkedin.com/company/sectorinformaticosa" target="_blank" rel="noreferrer noopener">
                            /sectorinformaticosa
                        </a>
                    </span>

                    <div className={styles.buttons}>
                        <div className={styles.despachosWrapper}>
                            <span className={styles.despachos}>DESPACHOS DIARIOS EN CABA Y GBA.</span>
                            <span className={styles.entregas}>Entregas a todo el país con su transporte de confianza.</span>
                        </div>
                        <button type="button" onClick={openCuentas}>
                            CUENTAS BANCARIAS
                        </button>
                        <Modal onClose={onCuentasClose} isOpen={cuentasIsOpen}>
                            <div className={styles.cuentasImg} />
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};
Support.propTypes = {
    members: PropTypes.arrayOf(
        PropTypes.shape({
            avatar: PropTypes.string.isRequired,
            map: PropTypes.string,
            zone: PropTypes.string,
            locations: PropTypes.string,
            name: PropTypes.string.isRequired,
            cellphone: PropTypes.string,
            telephone: PropTypes.string,
            email: PropTypes.string.isRequired,
            web: PropTypes.string.isRequired,
        })
    ).isRequired,
};
export default Support;
