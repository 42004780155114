import React from 'react';
import styles from './Heading.module.css';
import imagePath from '../../../utils/imagePath';

const style = {
    backgroundImage: `url(${imagePath('/img/backgrounds/alliances.svg')})`,
};

const Heading = () => {
    return (
        <div className={styles.container} id="heading" style={style}>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>LINEA DE EQUIPOS</h2>
                <p className={styles.text}>Mira nuestras impresoras, duplicadores digitales e impresoras / fotocopiadoras multifunción de alta calidad.</p>
            </div>
        </div>
    );
};

export default Heading;
