import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './Member.module.css';
import Phone, { PHONE_TYPE } from './Phone/Phone';
import imagePath from '../../../utils/imagePath';

const Member = ({ member }) => {
    const { title, avatar, map, zone, locations, name, cellphone, telephone, email, web } = member;
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {title && <h3 className={styles.title}>{title}</h3>}

                <div className={clsx(styles.iconWrapper, { [styles.hasMap]: !!map })}>
                    <img className={styles.avatar} alt="avatar" src={imagePath(avatar)} />
                    {map && (
                        <>
                            <img className={styles.vline} alt="" src={imagePath('/img/contact/line.svg')} />
                            <img className={styles.map} alt="map" src={imagePath(map)} />
                        </>
                    )}
                </div>

                <h3 className={styles.name}>{name}</h3>

                {cellphone && <Phone type={PHONE_TYPE.CELLPHONE} number={cellphone} />}
                {telephone && <Phone type={PHONE_TYPE.TELEPHONE} number={telephone} />}

                <span className={styles.email}>
                    <img alt="" src={imagePath('/img/icons/email.svg')} />
                    <img alt="" src={imagePath('/img/icons/skype.svg')} />
                    <a href={`mailto:${email}`}>{email}</a>
                </span>
                <span className={styles.web}>
                    <img alt="" src={imagePath('/img/icons/web.svg')} />
                    <a href={`http://${web}`}>{web}</a>
                </span>
            </div>
        </div>
    );
};
Member.propTypes = {
    member: PropTypes.shape({
        title: PropTypes.string,
        avatar: PropTypes.string.isRequired,
        map: PropTypes.string,
        zone: PropTypes.string,
        locations: PropTypes.string,
        name: PropTypes.string.isRequired,
        cellphone: PropTypes.string,
        telephone: PropTypes.string,
        email: PropTypes.string.isRequired,
        web: PropTypes.string.isRequired,
    }).isRequired,
};

export default Member;
