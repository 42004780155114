import React from 'react';
import clsx from 'clsx';
import styles from './Header.module.css';
import imagePath from '../../utils/imagePath';

const Header = () => {
    return (
        <div className={styles.container}>
            <p>
                <img className={clsx(styles.phone, styles.icon)} alt="phone" src={imagePath('/img/header/phone.svg')} />
                LLAMANOS AHORA
                <a href="tel:+541141171900">(+54) 11 4117-1900</a>
            </p>
            <p>
                <img className={clsx(styles.location, styles.icon)} alt="phone" src={imagePath('/img/header/location.svg')} />
                <a href="https://goo.gl/maps/weP8DXaRVB9L8RTH7" target="_blank" rel="noopener noreferrer">
                    NUESTRA UBICACIÓN
                </a>
            </p>
            <p>
                <img className={clsx(styles.mail, styles.icon)} alt="phone" src={imagePath('/img/header/mail.svg')} />
                <a href="mailto:info@sectorinformatico.com.ar">info@sectorinformatico.com.ar</a>
            </p>
        </div>
    );
};

export default Header;
