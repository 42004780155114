import React from 'react';
import Superheros from '../Shared/Superheros/Superheros';
import Company from './Company/Company';
import Alliances from './Alliances/Alliances';
import BU from './BU/BU';
import Separator from './Separator/Separator';
import Contact from './Contact/Contact';

const superheros = [
    {
        img: '/img/superheros/home/1.jpg',
        title: 'IMPORTADORES DE INSUMOS Y REPUESTOS DE IMPRESIÓN',
        subtitle: 'Nos distinguimos por las alianzas estratégicas que tenemos con las fábricas más importantes del mercado internacional.',
        color: 'rgba(255,0,15,0.85)',
    },
    {
        img: '/img/superheros/home/2.jpg',
        title: 'INSUMOS Y SOPORTE TÉCNICO PARA EL RECICLADOR',
        subtitle: 'Amplio catálogo para el reciclado de cartuchos laser y copiadoras. Staff especializado y laboratorio de pruebas.',
        color: 'rgba(150,150,150,0.85)',
    },
    {
        img: '/img/superheros/home/3.jpg',
        title: 'CANAL AUTORIZADO RICOH LATIN AMERICA',
        subtitle: 'Desde 2015 ofrecemos todo el porfolio de productos Ricoh, líder mundial en soluciones de impresión.',
        color: 'rgba(51,45,44,0.85)',
    },
    {
        img: '/img/superheros/home/4.jpg',
        title: 'SOLUCIÓN INTEGRAL PARA EMPRESAS DE COSTO POR COPIA',
        subtitle: 'Contamos con la mayor experiencia para ofrecerles las mejores opciones en equipamientos, insumos y repuestos.',
        color: 'rgba(255,0,15,0.85)',
    },
];

const Home = () => {
    return (
        <div>
            <Superheros superheros={superheros} />
            <Company />
            <Alliances />
            <BU />
            <Separator />
            <Contact />
        </div>
    );
};

export default Home;
