import React from 'react';
import clsx from 'clsx';
import styles from './Allianes.module.css';
import imagePath from '../../utils/imagePath';

const style = {
    backgroundImage: `url(${imagePath('/img/backgrounds/alliances.svg')})`,
};

const Alliances = () => {
    return (
        <div className={styles.container} id="alliances" style={style}>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>ALIANZAS ESTRATÉGICAS</h2>
                <p className={styles.text}>
                    Conectados con el mundo, estableciendo relaciones comerciales con las mejores marcas del mercado, GARANTIZANDO el éxito de nuestros clientes.
                </p>

                <p className={styles.quote}>
                    En 2002 fuimos nombrados REPRESENTANTES EXCLUSIVOS de TREND TONE IMAGING para la venta de toner en Argentina y Mercosur. Fábrica Taiwanesa de toner láser y
                    copier líder mundial por excelencia (también dedicada a los colorantes para la industria textil), que nos permite ofrecer un producto de estabilidad y
                    perfomance confiable y posicionarnos entre los mejores proveedores de toner en el país hoy en día.
                </p>

                <div className={styles.iconsWrapper}>
                    <img className={clsx(styles.icon, styles.iso)} alt="tti" src={imagePath('/img/icons/iso.png')} />
                </div>

                <p className={styles.text}>Provisión de productos y servicios con niveles de calidad y garantía que nos diferencian en el mercado.</p>
            </div>
        </div>
    );
};

export default Alliances;
