import React from 'react';
import styles from './Contact.module.css';
import Sales from './Sales/Sales';
import Support from './Support/Support';
import Form from './Form/Form';
import Map from './Map/Map';

const salesMembers = [
    {
        avatar: '/img/contact/Staff-PabloLeguizamon.jpg',
        zone: 'ZONA 2',
        locations: 'LA PAMPA / SAN LUIS / MENDOZA / SAN JUAN / CORDOBA',
        name: 'PABLO LEGUIZAMON',
        cellphone: '(+54) 911 6497-6383',
        telephone: '(+54) 11 4117-1909',
        email: 'pleguizamon@sectorinformatico.com.ar',
        web: 'sectorinformatico.com.ar',
    },
    {
        avatar: '/img/contact/Staff-MatiasCastrovichi.jpg',
        zone: 'ZONA 4',
        locations: 'SANTA FE / MISIONES / ENTRE RIOS / CORRIENTES / FORMOSA / CHACO',
        name: 'MATIAS CASTROVINCI',
        cellphone: '(+54) 911 3842-1290',
        telephone: '(+54) 11 4117-1916',
        email: 'mcastro@sectorinformatico.com.ar',
        web: 'sectorinformatico.com.ar',
    },
];
const supportMembers = [
    {
        title: 'SOPORTE TÉCNICO',
        avatar: '/img/contact/Staff-LeandroCremona.jpg',
        name: 'LEONARDO CREMONA',
        email: 'lcremona@sectorinformatico.com.ar',
        web: 'sectorinformatico.com.ar',
    },
];

const Contact = () => {
    return (
        <div className={styles.container} id="contact">
            <Sales members={salesMembers} />
            <Support members={supportMembers} />
            <div className={styles.formMapWrapper}>
                <Form />
                <Map />
            </div>
        </div>
    );
};

export default Contact;
