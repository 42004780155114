import React from 'react';
import styles from './Map.module.css';
import imagePath from '../../../utils/imagePath';

const Map = () => {
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <img alt="" src={imagePath('/img/icons/location.svg')} />
                VISITANOS
                <a className={styles.address} href="https://goo.gl/maps/hADU1HDStpUN3yqm8" target="_blank" rel="noopener noreferrer">
                    IRALA 231, CABA, ARGENTINA
                </a>
            </div>

            <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.9416282014276!2d-58.37095452251574!3d-34.63091535893829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccb1b5fc6ae09%3A0xd528fe969a724f75!2sSector%20Inform%C3%A1tico!5e0!3m2!1sen!2sar!4v1721241852464!5m2!1sen!2sar"
                loading="lazy"
                allowFullScreen=""
                style={{ border: 0 }}
                referrerPolicy="no-referrer-when-downgrade"
                className={styles.googleMap}
            />
        </div>
    );
};

export default Map;
