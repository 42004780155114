import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Sales.module.css';
import Member from '../Member/Member';
import imagePath from '../../../utils/imagePath';

const Sales = ({ members }) => {
    const membersNode = useMemo(() => {
        return members.map(member => {
            return <Member member={member} key={member.name} />;
        });
    }, [members]);
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <h2>
                    <img alt="" src={imagePath('/img/icons/location.svg')} />
                    CONTACTO / VENTAS <a href="tel:+54 (11) 4117-1900">+54 (11) 4117-1900</a>
                </h2>
                <h5>ATENCIÓN TELEFÓNICA DE LUNES A VIERNES DE 8.30 A 12.30 Y 13.30 A 17.30 HS</h5>
            </div>
            <div className={styles.membersContainer}>{membersNode}</div>
        </div>
    );
};
Sales.propTypes = {
    members: PropTypes.arrayOf(
        PropTypes.shape({
            avatar: PropTypes.string.isRequired,
            map: PropTypes.string,
            zone: PropTypes.string,
            locations: PropTypes.string,
            name: PropTypes.string.isRequired,
            cellphone: PropTypes.string,
            telephone: PropTypes.string,
            email: PropTypes.string.isRequired,
            web: PropTypes.string.isRequired,
        })
    ).isRequired,
};
export default Sales;
