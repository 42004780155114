import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './Phone.module.css';
import imagePath from '../../../../utils/imagePath';

export const PHONE_TYPE = {
    CELLPHONE: 'mobile',
    TELEPHONE: 'telephone',
};

const Phone = ({ number, type }) => {
    const icon = useMemo(() => {
        if (type === PHONE_TYPE.CELLPHONE) return '/img/icons/cell.svg';
        return '/img/icons/tel.svg';
    }, [type]);
    const name = useMemo(() => {
        if (type === PHONE_TYPE.CELLPHONE) return 'CEL';
        return 'TEL';
    }, [type]);
    return (
        <div className={clsx(styles.container, { [styles.tel]: type === PHONE_TYPE.TELEPHONE, [styles.cell]: type === PHONE_TYPE.CELLPHONE })}>
            <img alt={type} className={styles.icon} src={imagePath(icon)} />
            {name}:
            <a className={styles.number} href={`tel:${number}`}>
                {number}
            </a>
        </div>
    );
};
Phone.propTypes = {
    number: PropTypes.string.isRequired,
    type: PropTypes.oneOf([PHONE_TYPE.CELLPHONE, PHONE_TYPE.TELEPHONE]).isRequired,
};

export default Phone;
