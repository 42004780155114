import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Bullets.module.css';
import imagePath from '../../utils/imagePath';

const style = {
    listStyleImage: `url(${imagePath('/img/icons/check.svg')})`,
};

const Bullets = ({ items }) => {
    const bulletsNode = useMemo(() => {
        return items.map(bullet => {
            return <li>{bullet}</li>;
        });
    }, [items]);

    return (
        <ul className={styles.list} style={style}>
            {bulletsNode}
        </ul>
    );
};
Bullets.propTypes = {
    items: PropTypes.array.isRequired,
};

export default Bullets;
