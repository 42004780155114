import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './Selector.module.css';
import imagePath from '../../../utils/imagePath';

const BUSelector = ({ bu, active, onClick }) => {
    const { icons, name } = bu;
    const icon = useMemo(() => {
        if (active) return icons.active;
        return icons.disabled;
    }, [active, icons]);
    return (
        <button className={clsx(styles.container, { [styles.active]: active })} type="button" onClick={onClick}>
            <div className={styles.wrapper}>
                <div className={styles.iconWrapper}>
                    <img className={styles.icon} alt="icon" src={imagePath(icon)} />
                </div>
                <h2 className={styles.name}>{name}</h2>
            </div>
        </button>
    );
};
BUSelector.propTypes = {
    bu: PropTypes.shape({
        icons: PropTypes.shape({
            active: PropTypes.string,
            disabled: PropTypes.string,
        }),
        name: PropTypes.string,
    }).isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func,
};
BUSelector.defaultProps = {
    active: false,
    onClick: () => {},
};

export default BUSelector;
