import React, { useMemo } from 'react';
import styles from './Company.module.css';
import CompanyItem from './Item/Item';

const companyItems = [
    {
        icon: '/img/icons/printer.svg',
        title: '¿Qué hacemos?',
        text:
            'Desde el año 2002, representamos a las fábricas más importantes de la industria de impresión. Importamos y comercializamos materias primas, partes, repuestos y equipamiento para brindarle a nuestros clientes la mejor solución. Nuestros productos alternativos cuentan con los estándares más altos de calidad, lo que permite a nuestros clientes garantizar una performance similar al OEM.',
    },
    {
        icon: '/img/icons/hands.svg',
        title: 'Nuestros clientes',
        text:
            'Nuestro porfolio de productos esta orientado a satisfacer las necesidades de nuestros canales: Empresas que brindan el servicio de costo por copia (MPS), Recicladores de cartuchos laser y cartuchos de copiadoras, mayoristas y minoristas de insumos de impresión y Centros de copiado.',
        cta: {
            label: '+ INFORMACIÓN',
            to: 'mailto:info@sectorinformatico.com.ar',
        },
    },
    {
        icon: '/img/icons/gears.svg',
        title: '¿Qué nos caracteriza?',
        text:
            'Somos una empresa consolidada y con un amplio conocimiento de nuestro mercado internacional. Nuestro foco central se basa en establecer alianzas estratégicas con los proveedores más comprometidos en fabricar productos de calidad y estabilidad similares a los OEM. Nos motiva trabajar codo a codo con nuestros clientes, entendiendo sus necesidades y trabajando en soluciones y desarrollos de nuevos productos. La innovación y la mejora continua en nuestros procesos, nos permite ser más eficientes en nuestra gestión.',
    },
];

const Company = () => {
    const companyItemsNode = useMemo(() => {
        return companyItems.map(item => {
            return <CompanyItem item={item} key={item.title} />;
        });
    }, []);
    return (
        <div className={styles.container} id="company">
            <div>
                <h2 className={styles.title}>Empresa</h2>
                <h3 className={styles.subtitle}>Estableciendo relaciones comerciales desde 2002</h3>

                <div className={styles.itemsWrapper}>{companyItemsNode}</div>
            </div>
        </div>
    );
};

export default Company;
