import React from 'react';
import PropTypes from 'prop-types';
import styles from './Item.module.css';
import Bullets from '../../../Shared/Bullets/Bullets';

const Item = ({ model }) => {
    const { name, subtitle, bullets, specs, img, pdf } = model;

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div>
                    <h3 className={styles.title}>{name}</h3>
                    <span className={styles.subtitle}>{subtitle}</span>
                    <Bullets items={bullets} />
                    <span className={styles.specs}>{specs}</span>

                    {pdf && (
                        <a href={pdf} target="_blank" rel="noopener noreferrer" className={styles.pdf}>
                            + VER PDF
                        </a>
                    )}
                </div>
            </div>
            <div className={styles.right}>
                <img alt={name} src={img} />
            </div>
        </div>
    );
};

Item.propTypes = {
    model: PropTypes.shape({
        name: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        bullets: PropTypes.arrayOf(PropTypes.string),
        specs: PropTypes.string,
        img: PropTypes.string,
        pdf: PropTypes.string,
    }).isRequired,
};

export default Item;
