import React from 'react';
import Superheros from '../Shared/Superheros/Superheros';
import Section from './Section/Section';
import Equipment from './Equipment/Equipment';

const superheros = [
    {
        img: '/img/superheros/ricoh/1.jpg',
        title: 'Construya su lugar de trabajo del mañana, hoy.',
        subtitle: 'Somos mucho más que solo impresoras y copiadoras.',
        color: 'rgba(255,0,15,0.85)',
    },
];

const Ricoh = () => {
    return (
        <>
            <Superheros superheros={superheros} />
            <Section />
            <Equipment />
        </>
    );
};

export default Ricoh;
