import React from 'react';
import styles from './Item.module.css';
import Bullets from '../../../Shared/Bullets/Bullets';

const bu = {
    title: 'RICOH LASER / COPIER / INKJET',
    bullets: ['Cartuchos Originales Laser.', 'Cartuchos Originales Copier.', 'Cartuchos Originales InkJet.', 'Repuestos Originales.'],
    img: '/img/bu/parts/image.jpg',
};
const Parts = () => {
    const { title, subtitle, bullets, img } = bu;

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <h2 className={styles.title}>{title}</h2>
                    <h3 className={styles.subtitle}>{subtitle}</h3>
                    <Bullets items={bullets} />
                </div>
                <div className={styles.right}>
                    <img className={styles.image} alt="" src={img} />
                </div>
            </div>
        </div>
    );
};

export default Parts;
