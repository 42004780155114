import React from 'react';
import styles from './Separator.module.css';

const Separator = () => {
    return (
        <div className={styles.container}>
            <p>
                Sector Informático es una empresa nacida en el año 2001.
                <br /> Importa y comercializa repuestos para el reprocesado de cartuchos de impresión láser y copiadoras.
            </p>
        </div>
    );
};

export default Separator;
