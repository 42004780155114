import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './Modal.module.css';

const ModalComponent = ({ isOpen, onClose, children }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal} overlayClassName={styles.modalOverlay}>
            <button onClick={onClose} className={styles.closeButton} type="button">
                X
            </button>
            {children}
        </Modal>
    );
};
ModalComponent.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.element.isRequired,
};
ModalComponent.defaultProps = {
    isOpen: false,
    onClose: () => {},
};

export default ModalComponent;
