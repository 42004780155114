import React, { useMemo } from 'react';
import styles from './Equipment.module.css';
import Heading from './Heading/Heading';
import Item from './Item/Item';

const equipment = [
    {
        name: 'SP 3710DN',
        subtitle: 'Satisfaga todas las necesidades, todos los presupuestos.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '34 PPM Monocromatico.', 'Controle la información y los costos de forma sencilla.'],
        specs: '600 X 600 PPP / 350MHZ / 128 MB / PCL6, PCL5E / USB 2.0 / LAN INALÁMBRICA / DUPLEX.',
        img: '/img/ricoh/equipment/SP3710DN.png',
        pdf: '/img/ricoh/equipment/SP3710DN.pdf',
    },
    {
        name: 'P502',
        subtitle: 'Solución de impresión empresarial.',
        bullets: ['Impresora.', '45 PPM Monocromatico.', 'Responde rápidamente a las exigencis del grupo de trabajo.'],
        specs: '1200 X 1200 PPP / 320GB / 2 GB / PCL6, PCL5E / PostScript 3 / Wireless LAN',
        img: '/img/ricoh/equipment/P502.jpg',
        pdf: '/img/ricoh/equipment/P502.pdf',
    },
    {
        name: 'SP 8400DN',
        subtitle: 'Convertir la personalización en productividad.',
        bullets: ['Copiadora.', '60 PPM Monocromatico.', 'Acomodar un ritmo más rápido en menos espacio.'],
        specs: '1200 X 1200 DPI / 1.46 GHZ / 2 GB / PCL5E, PCL6 / HIGH SPEED USB / POSTSCRIPT3 / PDF DIRECT.',
        img: '/img/ricoh/equipment/SP8400DN.png',
        pdf: '/img/ricoh/equipment/MP301SPF.pdf',
    },
    {
        name: 'MP 301SPF',
        subtitle: 'Multitarea, asequible y productivo a su conveniencia.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '31 PPM Monocromatico.', 'Un MFP elegante, compacto y eficiente para tu ciclo de trabajo.'],
        specs: '600 X 600 PPP / 533 MHZ / 1GB RAM / ETHERNET / USB2.0 / LAN INALÁMBRICA.',
        img: '/img/ricoh/equipment/MP301SPF.png',
        pdf: '/img/ricoh/equipment/MP301SPF.pdf',
    },
    {
        name: 'MP 402SPF',
        subtitle: 'Acérquese a la productividad todo en uno.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '42 PPM Monocromatico.', 'Controle el flujo de información desde el escritorio.'],
        specs: '600 X 600 PPP / 1.46 GHZ / 2 GB / 320 GB / PCL5E/6 / ETHERNET / USB 2.0 / BLUETOOTH / LAN INALÁMBRICA.',
        img: '/img/ricoh/equipment/MP402SPF.png',
        pdf: '/img/ricoh/equipment/MP402SPF.pdf',
    },
    {
        name: 'IM 430 F',
        subtitle: 'Asistente inteligente de oficina.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '45 PPM Monocromatico.', 'Herramientas inteligentes de flujo de trabajo.'],
        specs: '600 X 600 PPP / 4MB / 2 GB / PCL6, PCL5E / 320 GB / Ricoh Smart Integration',
        img: '/img/ricoh/equipment/IM430F.jpg',
        pdf: '/img/ricoh/equipment/IM430F.pdf',
    },
    {
        name: 'MP 501SPF / 601PF',
        subtitle: 'La combinación ideal de comodidad y productividad.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '52 PPM / 62 PPM Monocromatico.', 'Multitareas a un ritmo acelerado en menos espacio.'],
        specs: '600 X 600 PPP / 2 GB RAM + DISCO DURO DE 320 GB / 1.46 GHZ / GIGABIT ETHERNET / USB 2.0 / LAN INALÁMBRICA / PCL5E/6.',
        img: '/img/ricoh/equipment/MP501SPF.png',
        pdf: '/img/ricoh/equipment/MP501SPF.pdf',
    },
    {
        name: 'IM 550 F',
        subtitle: 'Dispositivo altamente inteligente.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '55 PPM Monocromatico.', 'Inteligente, productiva y totalmente confiable.'],
        specs: '600 X 600 PPP / 4MB / 2 GB / PCL6, PCL5E / 320 GB / Ricoh Smart Integration',
        img: '/img/ricoh/equipment/IM550F.jpg',
        pdf: '/img/ricoh/equipment/IM550F.pdf',
    },
    {
        name: 'SP C 842DN',
        subtitle: 'Transforme el color de alto volumen en algo altamente productivo.',
        bullets: ['Impresora.', '60 PPM Monocromatico.', 'Haga su trabajo más personal y productivo.'],
        img: '/img/ricoh/equipment/SPC842DN.png',
        pdf: '/img/ricoh/equipment/SPC842DN.pdf',
    },
    {
        name: 'C301',
        subtitle: 'Color vibrante para Empresas.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '25 PPM Color.', 'Imprimí desde cualquier dispositivo.'],
        specs: '600 X 600 PPP / 256MB / 2 GB / PCL6, PCL5E / PostScript 3 / USB 2.0',
        img: '/img/ricoh/equipment/C301.jpg',
        pdf: '/img/ricoh/equipment/C301.pdf',
    },
    {
        name: 'MP C307 / C407',
        subtitle: 'Convierte el espacio limitado en una ventaja.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '31 PPM Monocromatico - Color / 42 PPM Monocromatico - 36 PPM Color.', 'Grupos de trabajo eficientes.'],
        specs: '2 GB (RAM) / 600 X 600 DPI / DUPLEX / 1.46 GHZ / 2 GB / 320 GB / PCL5C/6 / POSTSCRIPT / PDF DIRECT /ETHERNET / USB 2.0.',
        img: '/img/ricoh/equipment/MPC307.png',
        pdf: '/img/ricoh/equipment/MPC307.pdf',
    },
    {
        name: 'MP 2555',
        subtitle: 'Convierta el día a día en una jornada fácil.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '25 PPM Monocromatico.', 'Añada valor a todo, en todas partes. Mejore la productividad sin esfuerzo.'],
        specs: '600 X 600 PPP / 2GBRAM + HDD DE 320 GB / DUPLEX / 1.46 GHZ / ETHERNET / USB HOST / POSTSCRIPT / PCL5E/PCL6.',
        img: '/img/ricoh/equipment/MP2555.png',
        pdf: '/img/ricoh/equipment/MP2555.pdf',
    },
    {
        name: 'IMC2000 - C2500',
        subtitle: 'Sea más productivo y eficiente.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '20 - 25 PPM Color.', 'Aumento del tiempo de productividad y rendimiento.'],
        specs: '1200 X 1200 PPP / 320GB / 2 GB / PCL6, PCL5E / PostScript 3 / Ethernet 10',
        img: '/img/ricoh/equipment/IMC2000.jpg',
        pdf: '/img/ricoh/equipment/IMC2000.pdf',
    },
    {
        name: 'MPC2004',
        subtitle: 'Elija la manera más inteligente de compartir sus mejores ideas.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '20 PPM Monocromatico.', 'Haga su trabajo más personal y productivo.'],
        specs: 'DUPLEX / 600 PPP / 1.33 GHZ / 2 GB RAM / DISCO DURO 250GB / PCL5E, PCL6, / ETHERNET / USB 2.0',
        img: '/img/ricoh/equipment/MPC2004.jpg',
        pdf: '/img/ricoh/equipment/MPC2004.pdf',
    },
    {
        name: 'SP 3710SFN',
        subtitle: 'Satisfaga todas las necesidades, todos los presupuestos.',
        bullets: ['Copiadora, impresora, facsmil, escaner.', '34 PPM Monocromatico.', 'Controle la información y los costos de forma sencilla.'],
        specs: '600 X 600 PPP / 400MHZ / 256 MB / PCL6, PCL5E / USB 2.0 / LAN INALÁMBRICA / DUPLEX.',
        img: '/img/ricoh/equipment/SP3710SFN.png',
        pdf: '/img/ricoh/equipment/SP3710DN.pdf',
    },
];

const Equipment = () => {
    const equipmentNode = useMemo(() => {
        return equipment.map(e => {
            return <Item model={e} />;
        });
    }, []);

    return (
        <div className={styles.container}>
            <Heading />
            <div className={styles.equipmentWrapper}>{equipmentNode}</div>
        </div>
    );
};

export default Equipment;
