import React from 'react';
import styles from './Item.module.css';
import Bullets from '../../../Shared/Bullets/Bullets';

const bu = {
    title: 'RICOH LASER / COPIER / INKJET',
    bullets: ['Impresoras y Equipos Mutifuncionales.', 'Equipos de Producción.', 'Plotters.', 'Proyectores.'],
    text: 'SOLICITE EL CATALOGO COMPLETO DE EQUIPAMIENTOS RICOH Y ENCUENTRE JUNTO A NUESTRO EQUIPO TÉCNICO LA MÁQUINA QUE MEJOR SE ADAPTE A SUS NECESIDADES.',
    button: '+ VER CATALOGO',
    img: '/img/bu/equipment/image.jpg',
};

const Equipment = () => {
    const { title, subtitle, bullets, text, img } = bu;

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <h2 className={styles.title}>{title}</h2>
                    <h3 className={styles.subtitle}>{subtitle}</h3>
                    <Bullets items={bullets} />
                    <p className={styles.text}>{text}</p>
                </div>
                <div className={styles.right}>
                    <img className={styles.image} alt="" src={img} />
                </div>
            </div>
        </div>
    );
};

export default Equipment;
