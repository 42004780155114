import React from 'react';

const Error404 = () => {
    return (
        <dib style={{ padding: '20px 10vw', display: 'flex' }}>
            <h2>404. No encontramos la pagina que estabas buscando.</h2>
        </dib>
    );
};

export default Error404;
