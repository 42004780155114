import React from 'react';
import styles from './Footer.module.css';
import imagePath from '../../utils/imagePath';

const Footer = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.newsletter}>
                    <h4>NEWSLETTER</h4>
                    <span>Dejanos tu correo para mas información</span>

                    <form className={styles.form}>
                        <input placeholder="Correo electrónico" />
                        <button type="submit">ENVIAR</button>
                    </form>
                </div>
                <div className={styles.address}>
                    <img alt="" src={imagePath('/img/icons/logo.svg')} />
                    <span>IRALA 231, CIUDAD AUTÓNOMA DE BUENOS AIRES, ARGENTINA</span>
                    <span>
                        TEL: <a href="tel:(+54) 11 4117-1900">(+54) 11 4117-1900</a> FAX: <a href="tel:(+54) 11 4117-1908">(+54) 11 4117-1908</a>
                    </span>
                    <span>INFO@SECTORINFORMATICO.COM.AR / SECTORINFORMATICO.COM.AR</span>
                </div>
            </div>
            <div className={styles.copyrightWrapper}>
                <p className={styles.copyright}>© 2019 SECTOR INFORMATICO. Todos los derechos reservados.</p>
                <p className={styles.asisehabla}>
                    Diseño{' '}
                    <a href="http://www.asisehabla.com/">
                        ASISEHABLA<sup>TM</sup>
                    </a>
                </p>
            </div>
        </>
    );
};

export default Footer;
