import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Superheros.module.css';
import imagePath from '../../utils/imagePath';

const SuperheroItem = ({ superhero }) => {
    const { title, img, subtitle, color } = superhero;

    const style = useMemo(() => {
        return {
            backgroundImage: `url(${imagePath(img)})`,
        };
    }, [img]);
    const wrapperStyle = useMemo(() => {
        return {
            backgroundColor: color,
        };
    }, [color]);

    return (
        <div className={styles.item} style={style}>
            <div className={styles.overlay} />
            <div className={styles.wrapper} style={wrapperStyle}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
                <a href="mailto:info@sectorinformatico.com.ar" className={styles.cta}>
                    Más Información
                </a>
            </div>
        </div>
    );
};
SuperheroItem.propTypes = {
    superhero: PropTypes.shape({
        img: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        width: PropTypes.string,
        color: PropTypes.string,
    }).isRequired,
};
export default SuperheroItem;
