import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styles from './App.module.css';
import Header from './Shared/Header/Header';
import Navbar from './Shared/Navbar/Navbar';
import Home from './Home/Home';
import Footer from './Shared/Footer/Footer';
import Ricoh from './Ricoh/Ricoh';
import Error404 from './404/404';
import { URLS } from './config';

function App() {
    return (
        <div className={styles.app}>
            <Header />
            <Router basename={process.env.PUBLIC_URL}>
                <Navbar />
                <Switch>
                    <Route exact path={URLS.ROOT} component={Home} />
                    <Route exact path={URLS.RICOH} component={Ricoh} />
                    <Route component={Error404} />
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
