import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './NavbarItem.module.css';

const NavbarItem = ({ title, active, to, onClick }) => {
    return (
        <NavLink to={to} className={clsx(styles.item, { [styles.active]: active })} onClick={onClick}>
            {title}
        </NavLink>
    );
};
NavbarItem.propTypes = {
    title: PropTypes.string.isRequired,
    active: PropTypes.bool,
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
NavbarItem.defaultProps = {
    active: false,
    onClick: () => {},
};
export default NavbarItem;
