import React, { useMemo, useState, useCallback } from 'react';
import styles from './BU.module.css';
import BUSelector from './Selector/Selector';
import Supplies from './Items/Supplies';
import Parts from './Items/Parts';
import Equipment from './Items/Equipment';
import imagePath from '../../utils/imagePath';

const businessUnits = [
    {
        name: 'Insumos y Componentes',
        icons: {
            active: '/img/icons/supplies.svg',
            disabled: '/img/icons/supplies-disabled.svg',
        },
    },
    {
        name: 'Ricoh Insumos y Repuestos',
        icons: {
            active: '/img/icons/parts.svg',
            disabled: '/img/icons/parts-disabled.svg',
        },
    },
    {
        name: 'Ricoh Equipamiento',
        icons: {
            active: '/img/icons/printer.svg',
            disabled: '/img/icons/printer-disabled.svg',
        },
    },
];

const BU = () => {
    const [activeBU, setActiveBU] = useState(0);

    const handleBUClick = useCallback(
        index => () => {
            setActiveBU(index);
        },
        []
    );

    const busSelectors = useMemo(() => {
        return businessUnits.map((bu, index) => {
            return <BUSelector bu={bu} active={index === activeBU} onClick={handleBUClick(index)} key={bu.name} />;
        });
    }, [activeBU, handleBUClick]);
    const busNode = useMemo(() => {
        return [<Supplies />, <Parts />, <Equipment />];
    }, []);
    const lineNode = useMemo(() => {
        return [
            <img className={styles.line} alt="" src={imagePath('/img/bu/dividers/1.svg')} />,
            <img className={styles.line} alt="" src={imagePath('/img/bu/dividers/2.svg')} />,
            <img className={styles.line} alt="" src={imagePath('/img/bu/dividers/3.svg')} />,
        ];
    }, []);

    return (
        <div className={styles.container} id="bu">
            <div className={styles.wrapper}>
                <h2 className={styles.title}>UNIDADES DE NEGOCIOS</h2>
                <p className={styles.text}>Estableciendo relaciones comerciales en base a la provisión de productos y servicios.</p>

                <div className={styles.selectors}>
                    {busSelectors}
                    {lineNode[activeBU]}
                </div>
                {busNode[activeBU]}
            </div>
        </div>
    );
};

export default BU;
