import React from 'react';
import styles from './Section.module.css';

const Section = () => {
    return (
        <div className={styles.container}>
            <h2>RICOH</h2>
            <p>
                Transformamos la forma en que las personas trabajan, se comunican e intercambian información a través de la tecnología. Lo llamamos movilidad de la información, y
                usted y su fuerza de trabajo obtienen la información que desea, donde y cuando la necesite.
            </p>
            <p>
                Con nuestra experiencia en la captura, administración y transformación de información, ofrecemos una amplia cartera de servicios y tecnologías que lo ayudan a
                innovar y crecer. Desde la redefinición de un lugar de trabajo hasta la provisión de soluciones que lo ayudan a ser más productivo y eficiente, hacemos que la
                información trabaje para usted.
            </p>
            <p>Y juntos, podemos imaginar el cambio.</p>
        </div>
    );
};

export default Section;
