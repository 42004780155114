import React, { useMemo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import clsx from 'clsx';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as faSolidCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as faLightCircle } from '@fortawesome/pro-light-svg-icons';
import SuperheroItem from './SuperheroItem';
import styles from './Superheros.module.css';

const CustomPaging = () => {
    return (
        <div className={styles.paging}>
            <FontAwesomeIcon icon={faSolidCircle} className="active" />
            <FontAwesomeIcon icon={faLightCircle} className="normal" />
        </div>
    );
};

const settings = {
    autoplay: true,
    fade: true,
    dots: true,
    draggable: false,
    arrows: false,
    rows: 1,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 0,
    pauseOnHover: false,
    autoplaySpeed: 10000,
    lazyLoad: 'ondemand',
    customPaging: CustomPaging,
};

const Superheros = ({ superheros }) => {
    const carouselItems = useMemo(() => {
        return superheros.map(superhero => {
            return (
                <div className="carouselItem" key={superhero.title}>
                    <SuperheroItem superhero={superhero} />
                </div>
            );
        });
    }, [superheros]);
    return (
        <div className={clsx('carousel')}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Slider {...settings}>{carouselItems}</Slider>
        </div>
    );
};
Superheros.propTypes = {
    superheros: PropTypes.arrayOf(
        PropTypes.shape({
            img: PropTypes.string,
            heading: PropTypes.string,
            title: PropTypes.string,
            subtitle: PropTypes.string,
        })
    ).isRequired,
};
export default Superheros;
