import React from 'react';
import PropTypes from 'prop-types';
import styles from './Item.module.css';
import imagePath from '../../../utils/imagePath';

const CompanyItem = ({ item }) => {
    const { icon, title, text, cta } = item;
    return (
        <div className={styles.wrapper}>
            <img className={styles.icon} alt="icon" src={imagePath(icon)} />
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.text}>{text}</p>
            {cta && (
                <a className={styles.cta} href={cta.to}>
                    {cta.label}
                </a>
            )}
        </div>
    );
};
CompanyItem.propTypes = {
    item: PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        cta: PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        }),
    }).isRequired,
};
export default CompanyItem;
