import React from 'react';
import styles from './Form.module.css';
import imagePath from '../../../utils/imagePath';

const Form = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>
                <img alt="" src={imagePath('/img/icons/email.svg')} />
                CONTACTO
            </h2>

            <form className={styles.form}>
                <input placeholder="NOMBRE Y APELLIDO / EMPRESA" />
                <input placeholder="CORREO ELECTRÓNICO" />
                <textarea placeholder="CONSULTA" />
                <button type="submit">ENVIAR</button>
            </form>
        </div>
    );
};

export default Form;
