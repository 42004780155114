import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";
import styles from "./Whatsapp.module.css";

const Whatsapp = ({ number }) => {
    return (
        <div className={styles.container}>
            <a href={`https://wa.me/${number}`} target="_blank" rel="noopener noreferrer" className={styles.iconWrapper} aria-label="Whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} className={styles.icon} />
            </a>
        </div>
    );
};
Whatsapp.propTypes = {
    number: PropTypes.number.isRequired,
};

export default Whatsapp;
