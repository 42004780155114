import React, { useMemo } from 'react';
import styles from './Item.module.css';
import imagePath from '../../../utils/imagePath';

const bu = {
    title: 'LASER / COPIER / INKJET',
    bullets: ['Cartuchos Alternativos Laser.', 'Cartuchos Alternativos Copier.', 'Insumos para el reciclado de cartuchos.'],
    text: 'Toner a granel y fraccionado / Opc – Cilindros / Chips / Reveladores / Doctor Blade / Wiper Blade / PCR / Rodillos  Magnéticos / Repuestos Alternativos.',
    logos: ['/img/bu/supplies/logos1.jpg'],
    img: '/img/bu/supplies/image.jpg',
};
const Supplies = () => {
    const { title, bullets, text, logos, img } = bu;

    const bulletsNode = useMemo(() => {
        return bullets.map(bullet => {
            return <li key={bullet}>{bullet}</li>;
        });
    }, [bullets]);
    const logosNode = useMemo(() => {
        return logos.map(logo => {
            return <img className={styles.logo} alt="" src={imagePath(logo)} key={logo} />;
        });
    }, [logos]);
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <h2 className={styles.title}>{title}</h2>
                    <ul>{bulletsNode}</ul>
                    <p className={styles.text}>{text}</p>
                    <div className={styles.logoWrapper}>{logosNode}</div>
                </div>
                <div className={styles.right}>
                    <img className={styles.image} alt="" src={imagePath(img)} />
                </div>
            </div>
        </div>
    );
};

export default Supplies;
