import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import styles from './Navbar.module.css';
import NavbarItem from './NavbarItem';
import { URLS } from '../../config';
import imagePath from '../../utils/imagePath';
import Whatsapp from '../Whatsapp/Whatsapp';

const Navbar = () => {
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = useCallback(() => {
        setIsOpen(value => !value);
    }, []);

    const navItems = (
        <>
            <NavbarItem title="EMPRESA" active={false} to={{ pathname: '/', hash: '#company' }} />
            <NavbarItem title="ALIANZAS ESTRATÉGICAS" active={false} to="/#alliances" />
            <NavbarItem title="NEGOCIOS" active={false} to="/#bu" />
            <NavbarItem title="RICOH" active={pathname === URLS.RICOH} to={URLS.RICOH} />
            <NavbarItem title="CONTACTO" active={pathname === URLS.ROOT} to="/#contact" />
        </>
    );

    const mobileNavItems = (
        <>
            <NavbarItem title="EMPRESA" active={false} to="/#company" onClick={handleMenuClick} />
            <NavbarItem title="ALIANZAS ESTRATÉGICAS" active={false} to="/#alliances" onClick={handleMenuClick} />
            <NavbarItem title="NEGOCIOS" active={false} to="/#bu" onClick={handleMenuClick} />
            <NavbarItem title="RICOH" active={pathname === URLS.RICOH} to={URLS.RICOH} onClick={handleMenuClick} />
            <NavbarItem title="CONTACTO" active={pathname === URLS.ROOT} to="/#contact" onClick={handleMenuClick} />
        </>
    );

    return (
        <div className={styles.container}>
            <a href={URLS.ROOT}>
                <img alt="logo" className={styles.logo} src={imagePath('/img/icons/logo.svg')} />
            </a>

            <div className={styles.linkWrapper}>{navItems}</div>

            <button onClick={handleMenuClick} type="button" className={styles.mobileButton}>
                &#9776;
            </button>

            <div className={clsx(styles.sidenav, { [styles.open]: isOpen })}>
                <NavbarItem title="&times;" active={false} to="#" onClick={handleMenuClick} />
                {mobileNavItems}
            </div>
            <Whatsapp number="5491131550197" />
        </div>
    );
};

export default Navbar;
